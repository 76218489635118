import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { CartContext } from "../../../context/shopContext";
import { useWindowDimension } from "../../utils/useWindowDimension";

const CartHeader = ({ quantity }) => {
  const { cart, estimatedCost } = useContext(CartContext);
  const { height } = useWindowDimension();
  const freeShipping = "You’re eligible for free shipping!";
  const shippingCharges = (charges) =>
    `You’re only $${Number(charges).toFixed(2)} away from free shipping!`;

  return (
    <div className={`mb-4 sm:mb-10 ${height > 741 && "mt-3"}`}>
      {cart.length > 0 ? (
        <div className="flex flex-col items-center w-full space-y-1">
          <div className="flex items-center">
            <h4 className="w-full font-dinnext text-base sm:text-lg md:text-2xl md:leading-7 text-center">
              Your Cart ({quantity}){" "}
            </h4>
            <StaticImage
              src="../../../images/Vector.png"
              alt="Cart"
              loading="lazy"
              placeholder="tracedSVG"
              formats={["webp", "auto"]}
              width={20}
              height={20}
            />
          </div>
          <p className="text-sm md:text-base text-center font-dinnext text-[#7C7C7C]">
            {/* For a limited time: EVERY ORDER SHIPS FREE */}
            {estimatedCost?.subtotalAmount?.amount >= 50
              ? freeShipping
              : shippingCharges(50 - estimatedCost?.subtotalAmount?.amount)}
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center w-full sm:px-10 md:px-[84px] mt-5 border-b-[0.5px] border-solid border-b-black">
          <h4 className="w-full font-dinnext text-base sm:text-lg md:text-2xl md:leading-7 text-center">
            Your Cart is Empty
          </h4>
        </div>
      )}
    </div>
  );
};

export default CartHeader;
